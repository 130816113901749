import { createTextColumn } from "react-datasheet-grid";
import createTextComponentWithTooltip from "../Utils/tooltipWrapper";

// リファクタリング済み：2024/12/20
export const createFloatSuffixColumn = (suffix = "") => {
  const formatBlurredInput = (value) =>
    typeof value === "number"
      ? value.toLocaleString("ja-JP", { maximumFractionDigits: 6 }) + suffix //カンマ区切り、小数6桁まで表示
      : "";

  return {
    ...createTextColumn({
      alignRight: true,
      formatBlurredInput: formatBlurredInput,
      parseUserInput: (value) => {
        const number = Number(value);
        return !isNaN(number) ? number : null;
      },
      parsePastedValue: (value) => {
        const number = Number(value.replace(/,/g, "")); // カンマ区切りはカンマを削除する
        return !isNaN(number) ? number : null;
      },
    }),
    component: createTextComponentWithTooltip(formatBlurredInput),
  };
};

export default createFloatSuffixColumn;
