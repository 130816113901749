/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadingActions, messageActions } from "../store/store";

export let httpClient = axios.create();

function useApi() {
  const loadingId = crypto.randomUUID();
  const dispatch = useDispatch();

  const connect = useCallback(
    async (axiosFunc, success, throwError = false) => {
      dispatch(loadingActions.start(loadingId));
      let result = await axiosFunc().catch((err) => {
        return err.response;
      });
      if (throwError) {
        if (result?.status !== 200) {
          throw new Error(result?.status + ":" + result?.data);
        }
      }
      if (result?.status !== 200) {
        dispatch(
          messageActions.setError(
            result?.status + "：" + result?.statusText + "\n" + result?.data
          )
        );
      } else {
        if (!!success) {
          dispatch(messageActions.setSuccess(success));
        }
      }
      dispatch(loadingActions.end(loadingId));
      return result;
    },
    [dispatch, loadingId]
  );

  return { connect };
}

export default useApi;
