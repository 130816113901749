import { textColumn } from "react-datasheet-grid";
import createTextComponentWithTooltip from "../Utils/tooltipWrapper";

// リファクタリング済み：2024/12/20
export const textColumnWithTooltip = {
  ...textColumn,
  component: createTextComponentWithTooltip(textColumn.formatBlurredInput),
};

export default textColumnWithTooltip;
