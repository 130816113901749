import { Button } from "@mui/material";
import React, { useState } from "react";
import { DataSheetGrid, keyColumn, textColumn } from "react-datasheet-grid";
import { FaFileExcel } from "react-icons/fa";
import createSelectColumn from "./Columns/SelectColumn";

// 会社一覧
const CompanySpreadsheet = ({ disabled = false }) => {
  function createConfirmationLetterData(
    id,
    code,
    name,
    type,
    language,
    responseDueDate,
    status
  ) {
    return { id, code, name, type, language, responseDueDate, status };
  }
  const rows = [
    {
      busyo: "部署1",
      name: "確認先　太郎",
      email: "mail@mail.com",
      role: "回答責任者",
    },
    {
      busyo: "部署1",
      name: "確認先　次郎",
      email: "jiro@mail.com",
      role: "回答作成者",
    },
  ];
  const [data, setData] = useState(rows);
  const columns = [
    {
      ...keyColumn("companyCode", textColumn),
      title: "会社コード",
      minWidth: 200,
    },
    {
      ...keyColumn("companyAbbreviation", textColumn),
      title: "会社略称",
      minWidth: 200,
    },
    {
      ...keyColumn("companyName", textColumn),
      title: "会社名",
      minWidth: 120,
    },
    {
      ...keyColumn("representativeName", textColumn),
      title: "代表者氏名",
    },
    {
      ...keyColumn("representativeTitle", textColumn),
      title: "代表者肩書",
    },
    {
      ...keyColumn("postCode", textColumn),
      title: "郵便番号",
    },
    {
      ...keyColumn(
        "prefecture",
        createSelectColumn({
          choices: [
            { value: "HOKKAIDO", label: "北海道" },
            { value: "AOMORI", label: "青森県" },
          ],
        })
      ),
      title: "都道府県",
    },
    {
      ...keyColumn("cityWardTownVillage", textColumn),
      title: "市区町村",
    },
    {
      ...keyColumn("restAddress", textColumn),
      title: "番地・ビル名など",
    },
    {
      ...keyColumn("addressEnglish", textColumn),
      title: "Address（English）",
    },
    {
      ...keyColumn("countryEnglish", textColumn),
      title: "Country（English）",
    },
    {
      ...keyColumn("postCodeEnglish", textColumn),
      title: "Post Code（English）",
    },
    {
      ...keyColumn(
        "registerStatus",
        createSelectColumn({
          choices: [
            { value: "COMPLETED", label: "完了" },
            { value: "UNCOMPLETED", label: "未了" },
          ],
        })
      ),
      title: "入力ステータス",
    },
  ];

  return (
    <>
      <Button startIcon={<FaFileExcel />}>CSフォーマットダウンロード</Button>
      <DataSheetGrid
        value={data}
        onChange={setData}
        columns={columns}
        gutterColumn={false}
        lockRows={disabled}
        autoAddRow
        height={300}
      />
    </>
  );
};

export default CompanySpreadsheet;
