import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "../store/store";

export default function Loading() {
  const loadingArray = useSelector((state) => state.loading.loadingArray);
  const message = useSelector((state) => state.message);
  const dispatch = useDispatch();
  // snackbar管理
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    setOpen(false);
    // snackbarちらつき防止
    setTimeout(() => {
      dispatch(messageActions.remove());
    }, 300);
  };

  // エラー管理
  useEffect(() => {
    if (loadingArray.length === 0 && !!message.message) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingArray, message]);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) =>
            // theme.zIndex.drawer + 1
            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={loadingArray.length !== 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={
          message.type === "error"
            ? { vertical: "top", horizontal: "center" }
            : { vertical: "bottom", horizontal: "left" }
        }
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          severity={message.type === "error" ? "error" : "success"}
          variant="filled"
        >
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
