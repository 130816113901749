import { MenuItem, Select } from "@mui/material";

// リファクタリング済み：2024/12/20
const createSelectComponent = ({ choices, disabled = false }) => {
  const SelectComponent = ({ rowData, setRowData }) => {
    return (
      <Select
        disabled={disabled}
        value={
          choices
            ?.map(({ value }) => value)
            .find((value) => value === rowData) ?? null
        }
        onChange={(choice) => {
          if (choice === null) return;
          setRowData(choice.target.value);
        }}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          minWidth: "0",
        }}
      >
        {choices?.map((choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            {choice.label}
          </MenuItem>
        ))}
      </Select>
    );
  };
  return SelectComponent;
};

const createSelectColumn = (props) => {
  const { choices = [] } = props;
  return {
    component: createSelectComponent(props),
    deleteValue: () => null,
    copyValue: ({ rowData }) =>
      choices?.find((choice) => choice.value === rowData)?.label,
    pasteValue: ({ value }) =>
      choices?.find((choice) => choice.label === value)?.value ?? null,
  };
};

export default createSelectColumn;
