import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as domLink } from "react-router-dom";
import DefaultPaper from "../../components/DefaultPaper";
import CompanyForm from "../../components/Form/CompanyForm";
import ProcedureForm from "../../components/Form/ProcedureForm";
import FormDialog from "../../components/FormDialog";
import CompanySpreadsheet from "../../components/SpreadSheets/CompanySpreadsheet";
import ProcedureSpreadsheet from "../../components/SpreadSheets/ProcedureSpreadsheet";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import useApi, { httpClient } from "../../hooks/useApi";
import { messageActions } from "../../store/store";
import getCompanyListHead, {
  companyDataMapper,
} from "../../utils/CompanyListHead";
import Constants from "../../utils/Constants";
import getProcedureListHead, {
  procedureDataMapper,
} from "../../utils/ProcedureListHead";

const Dashboard = ({ title }) => {
  const [visibleRequiredOnly, setVisibleRequiredOnly] = useState(true);
  const { connect } = useApi();
  const role = useSelector((state) => state.role.role);

  // クライアント一覧ドロップダウン関連
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  //    クライアント一覧の取得
  const dispatch = useDispatch();
  const fetchClients = async () => {
    // 監査人の場合
    if (role === Constants.ROLE.AUDITOR) {
      const result = await connect(() => {
        return httpClient.get("../../api/auditor/GetClientList");
      });
      if (result?.data?.data) {
        setClients(
          result?.data?.data?.map((row) => {
            return {
              value: row.id,
              name: row.companyName,
              code: row.companyCode,
              isAdministrator: row.isAdministrator,
            };
          })
        );
        setSelectedClient("");
      } else {
        dispatch(
          messageActions.setError(
            "担当クライアントが存在しません。\nクライアントを新規作成するか、既存のクライアントの担当者に登録してください。"
          )
        );
      }
    } // クライアントの場合
    else if (role === Constants.ROLE.CLIENT) {
      const result = await connect(() => {
        return httpClient.get("../../api/client/GetClientList");
      });
      if (result?.data?.data) {
        setClients(
          result?.data?.data?.map((row) => {
            return {
              value: row.id,
              name: row.companyName,
              code: row.companyCode,
              isAdministrator: row.isAdministrator,
            };
          })
        );
      } else {
        dispatch(
          messageActions.setError(
            "担当クライアントが存在しません。\n監査担当者に問い合わせてください。"
          )
        );
      }
    }
  };
  // 初期表示
  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);
  // クライアントの場合、取得したクライアントをドロップダウンで選択する。
  useEffect(() => {
    if (role === Constants.ROLE.CLIENT) {
      setSelectedClient(clients[0]);
      handleClientChange(clients[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, clients]);

  //    クライアント変更イベント
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    // 対象期間の取得
    const fetchTargetPeriods = async () => {
      const result = await connect(() => {
        return httpClient.get(
          `../../api/auditor/GetPeriodList?companyId=${selectedOption?.value}`
        );
      });
      setTargetPeriods(
        result?.data?.data?.map((row) => {
          return {
            value: row.id,
            label: row.title,
          };
        })
      );
    };
    if (selectedOption) {
      fetchTargetPeriods();
    }
    setSelectedTargetPeriod("");

    // Step2以降
    // setNotificationRows(
    //   notificationData.filter((row) => {
    //     return selectedOption?.value === row.clientCode;
    //   }) ?? []
    // );
  };

  // 対象期間ドロップダウン関連
  const [targetPeriods, setTargetPeriods] = useState([]);
  const [selectedTargetPeriod, setSelectedTargetPeriod] = useState("");

  // クライアント削除関連
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const handleDeleteClient = () => {
    // TODO:delete処理
    const deleteData = async () => {
      await connect(() => {
        return httpClient.post(
          `/api/auditor/DeleteClientCompany?id=${selectedClient.value}`
        );
      }, "削除しました");
      fetchClients();
    };
    deleteData();
    handleDeleteConfirmationClose();
  };
  const handleDeleteConfirmationClose = () => {
    setOpenDeleteConfirmation(false);
  };

  // 通知フィルター
  // const [visibleUnreadOnly, setVisibleUnreadOnly] = useState(true);
  // const [notificationRows, setNotificationRows] = useState([]);

  // 確認状フィルター
  const [companyRows, setCompanyRows] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  // 会社一括編集関連
  const [companySpreadsheetDialogOpen, setCompanySpreadsheetDialogOpen] =
    useState(false);

  // 確認手続一括編集関連
  const [procedureSpreadsheetDialogOpen, setProcedureSpreadsheetDialogOpen] =
    useState(false);

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <DefaultPaper title="クライアント基本情報">
        <Grid container>
          <Grid item xs={3}>
            <Autocomplete
              label="クライアントコード"
              options={clients}
              value={selectedClient}
              disabled={clients?.length === 0 || role === Constants.ROLE.CLIENT}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box
                    key={key}
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    {option.code + " | " + option.name}
                  </Box>
                );
              }}
              getOptionLabel={(option) => option?.code ?? ""}
              onChange={(_, selectedOption) => {
                handleClientChange(selectedOption);
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="クライアントコード"
                  sx={{ maxWidth: "15rem" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel>クライアント名</InputLabel>
            {selectedClient && (
              <Link
                color="inherit"
                noWrap
                to={"/" + role + `/company/${selectedClient?.value}`}
                padding="16.5px 14px"
                component={domLink}
              >
                {selectedClient?.name}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="対象期間"
              value={selectedTargetPeriod}
              onChange={(e) => {
                return setSelectedTargetPeriod(e.target.value);
              }}
              disabled={!selectedClient || targetPeriods?.length === 0}
              select
            >
              {targetPeriods?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3} textAlign="right">
            {selectedClient?.isAdministrator &&
              role === Constants.ROLE.AUDITOR && (
                // 監査人 かつ クライアントの管理者の場合のみ表示
                <Button
                  color="warning"
                  //disabled={!getValues("clientName")}
                  onClick={() => {
                    setOpenDeleteConfirmation(true);
                  }}
                >
                  クライアント削除
                </Button>
              )}
          </Grid>
        </Grid>
      </DefaultPaper>

      {/* {false && (
        <DefaultPaper title={"通知一覧"}>
          <Box textAlign={"right"}>
            <FormControlLabel
              control={
                <Switch
                  checked={visibleUnreadOnly}
                  onChange={(e) => setVisibleUnreadOnly(e.target.checked)}
                />
              }
              label="未読のみ表示"
            />
          </Box>
          <AddEditDialogTable
            // getPath="../api/mscsadmin/GetAuditTeamList"
            // deletePath="../api/mscsadmin/DeleteAuditTeam"
            keyColumnName="notificationId"
            headCells={getNotificationListHead()}
            listName="通知"
            disableEdit
            disableAdd
            disableDialog
            testRows={
              visibleUnreadOnly
                ? notificationRows.filter((row) => row.read === "未読")
                : notificationRows
            }
          ></AddEditDialogTable>
        </DefaultPaper>
      )} */}
      <DefaultPaper title={"会社一覧"}>
        <Grid container>
          <Grid item xs={6}>
            {/* Step2以降 */}
            {/* <Button
              startIcon={<Edit />}
              onClick={() => setCompanySpreadsheetDialogOpen(true)}
            >
              一括編集
            </Button> */}
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            <FormControlLabel
              control={
                <Switch
                  checked={visibleRequiredOnly}
                  onChange={(e) => setVisibleRequiredOnly(e.target.checked)}
                />
              }
              label="必須項目のみ表示"
            />
          </Grid>
        </Grid>
        <AddEditDialogTable
          getPath={`../api/auditor/GetCompanyList?clientId=${selectedClient?.value}`}
          deletePath="../api/auditor/DeleteCompany"
          headCells={getCompanyListHead(!visibleRequiredOnly)}
          rowMapper={companyDataMapper}
          listName="会社"
          editDialogContent={CompanyForm}
          dialogProps={{
            clientCode: selectedClient?.code,
            clientId: selectedClient?.value,
          }}
          _setRows={setCompanyRows}
          _setSelected={setSelectedCompanies}
          disableAdd={!selectedClient}
        ></AddEditDialogTable>
      </DefaultPaper>

      <DefaultPaper title={"確認手続一覧"}>
        {/* Step2以降 */}
        {/* <Button
          startIcon={<Edit />}
          onClick={() => setProcedureSpreadsheetDialogOpen(true)}
        >
          一括編集
        </Button> */}
        <AddEditDialogTable
          getPath={`../api/auditor/GetProcedureList?companyIds=${selectedCompanies?.join(
            ","
          )}&targetPeriodId=${selectedTargetPeriod}`}
          deletePath="../api/auditor/DeleteProcedure"
          headCells={getProcedureListHead()}
          rowMapper={procedureDataMapper}
          listName="確認手続"
          editDialogContent={ProcedureForm}
          dialogProps={{
            companyList: companyRows
              ?.filter((row) => selectedCompanies.indexOf(row.id) > -1)
              ?.map((row) => {
                console.log(companyRows);
                console.log(selectedCompanies);
                return { value: row.id, label: row.companyCode };
              }),
            targetPeriodId: selectedTargetPeriod,
          }}
          disableAdd={
            !selectedClient ||
            !selectedTargetPeriod ||
            selectedCompanies?.length === 0 ||
            role === Constants.ROLE.CLIENT
          }
          disableDelete={role === Constants.ROLE.CLIENT}
          disableEdit={role === Constants.ROLE.CLIENT}
        ></AddEditDialogTable>
      </DefaultPaper>
      {false && <DefaultPaper title={"確認実施状況一覧"}></DefaultPaper>}

      {/* クライアント削除確認Alertダイアログ */}
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleDeleteConfirmationClose}
      >
        <DialogContent>
          <DialogTitle>クライアントを削除します。よろしいですか？</DialogTitle>
          <DialogContentText>
            ※クライアントに紐づく会社、手続等すべてが削除されます。
            <br />
            ※この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClient} color="warning">
            削除
          </Button>
          <Button onClick={handleDeleteConfirmationClose}>キャンセル</Button>
        </DialogActions>
      </Dialog>

      {/* 会社一括編集ダイアログ */}
      <FormDialog
        open={companySpreadsheetDialogOpen}
        title={"会社情報の一括編集"}
        onClose={() => setCompanySpreadsheetDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <CompanySpreadsheet />
        <Button onClick={() => setCompanySpreadsheetDialogOpen(false)}>
          保存
        </Button>
      </FormDialog>

      {/* 確認手続一括編集ダイアログ */}
      <FormDialog
        open={procedureSpreadsheetDialogOpen}
        title={"確認手続の一括編集"}
        onClose={() => setProcedureSpreadsheetDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <ProcedureSpreadsheet />
        <Button onClick={() => setProcedureSpreadsheetDialogOpen(false)}>
          保存
        </Button>
      </FormDialog>
    </>
  );
};
export default Dashboard;
