import { MenuItem } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

function SettingMenuList({ settingLinkList = [] }) {
  const menuItemList = [
    <MenuItem component="a" href="/.auth/logout" key="logout">
      ログアウト
    </MenuItem>,
  ];
  for (const link of settingLinkList) {
    menuItemList.push(
      <MenuItem component={Link} to={link.href} key={link.href}>
        {link.text}
      </MenuItem>
    );
  }
  return <>{menuItemList}</>;
}

export default SettingMenuList;
