import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useApi, { httpClient } from "../hooks/useApi";
import { roleActions, rolesActions } from "../store/store";
import Constants from "../utils/Constants";

function Index() {
  const path = useLocation().pathname;
  const { connect } = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      let roles = [];
      let role = path.split("/")[1];
      const result = await connect(() => {
        return httpClient.get("/.auth/me");
      });
      if (
        !result?.data?.clientPrincipal?.userRoles?.includes("authenticated")
      ) {
        // 未認証の場合はログイン画面へリダイレクトする
        window.location.href = `/.auth/login/aad?post_login_redirect_uri=${path}`;
      } else {
        setIsAuthenticated(true);
        // ログイン済みの場合はロールの確認を行う
        roles = result?.data?.clientPrincipal?.userRoles;
        dispatch(rolesActions.setRoles(roles));
        switch (role) {
          case Constants.ROLE.AUDITOR:
            if (!roles?.includes("AUDITOR")) {
              navigate("/unauthorized");
            }
            break;
          case Constants.ROLE.CLIENT:
            if (!roles?.includes("CLIENT")) {
              navigate("/unauthorized");
            }
            break;
          case Constants.ROLE.CONFIRMER:
            if (!roles?.includes("CONFIRMER")) {
              navigate("/unauthorized");
            }
            break;
          case Constants.ROLE.ADMIN:
            if (!roles?.includes("ADMINISTRATOR")) {
              navigate("/unauthorized");
            }
            break;
          default:
        }
      }
      dispatch(roleActions.setRole(role));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return <>{isAuthenticated && <Outlet />}</>;
}

export default Index;
