import { Tooltip } from "@mui/material";
import { textColumn } from "react-datasheet-grid";

export default function createTextComponentWithTooltip(
  formatBlurredInput = (value) => String(value ?? "")
) {
  return function TextComponentWithTooltip(props) {
    const { rowData = "" } = props;
    const TextComponent = textColumn.component;

    return (
      <Tooltip title={formatBlurredInput(rowData)} placement="top-start">
        {/* inputがdisabledでもtooltipが表示されるようにするためspanで囲む */}
        <span style={{ width: "100%", height: "100%" }}>
          <TextComponent {...props} />
        </span>
      </Tooltip>
    );
  };
}
