/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";

// バリデーション定義
const schema = z.object({
  id: z.string(),
  confirmationLetterId: z.string(),
  userId: z.string(),
  department: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  name: z
    .string()
    .min(1, { message: MESSAGES.REQUIRED })
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  eMailAddress: z
    .string()
    .min(1, { message: MESSAGES.REQUIRED })
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    )
    .email({ message: MESSAGES.EMAIL_ERROR }),
  authority: z.string().min(1, MESSAGES.REQUIRED),
});

const ConfirmerForm = ({
  targetData = {},
  authorityType,
  confirmationLetterId,
  closeHandler,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();

  const [disableEmail, setDisableEmail] = useState(false);

  // 新規作成時にフォームをクリア
  useEffect(() => {
    console.log(targetData);
    if (Object.keys(targetData).length !== 0) {
      reset({ ...targetData });
      setDisableEmail(true);
    } else {
      reset({
        id: "",
        confirmationLetterId: confirmationLetterId,
        userId: "",
        department: "",
        name: "",
        eMailAddress: "",
        authority: "",
      });
      setDisableEmail(false);
    }
  }, [targetData, confirmationLetterId]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("/api/confirmer/UpsertConfirmer", data);
    }, "保存しました");
    console.log("post end", data);
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="department"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="部署"
              {...field}
              error={!!errors.department}
              helperText={errors.department ? errors.department.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="氏名"
              required
              {...field}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="eMailAddress"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="メールアドレス"
              disabled={disableEmail}
              required
              {...field}
              error={!!errors.eMailAddress}
              helperText={
                errors.eMailAddress ? errors.eMailAddress.message : ""
              }
            />
          )}
        />
        <br />
        <FormControl sx={{ minWidth: "5rem" }}>
          <Controller
            name="authority"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="種別"
                select
                required
                {...field}
                error={!!errors.authority}
                helperText={errors.authority ? errors.authority.message : ""}
              >
                {authorityType?.map((row) => (
                  <MenuItem value={row.code} key={row.code}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </FormControl>
        <br />
        <Button type="submit">保存</Button>
      </form>
    </>
  );
};

export default ConfirmerForm;
