import React, { useEffect, useState } from "react";
import { DynamicDataSheetGrid, keyColumn } from "react-datasheet-grid";
import { Controller } from "react-hook-form";
import Constants from "../../../utils/Constants";
import createFloatSuffixColumn from "../Columns/FloatSuffixColumn";
import createSelectColumn from "../Columns/SelectColumn";
import textColumnWithTooltip from "../Columns/TextColumnWithTooltip";
import { differenceChoices } from "../Utils/DifferenceChoices";
// 持分
const EquitySpreadsheet = ({
  disableConfirmation,
  disableResponse,
  confirmationLetterId,
  presenceOrAbsenceList,
  control,
}) => {
  // ドロップダウンの初期化
  const [presenceOrAbsenceChoices, setPresenceOrAbsenceChoices] = useState([]);
  useEffect(
    () => {
      setPresenceOrAbsenceChoices(
        presenceOrAbsenceList?.map((type) => {
          return { value: type.code, label: type.name };
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presenceOrAbsenceList]
  );
  // ヘッダー定義
  const columns = [
    {
      ...keyColumn("details1", textColumnWithTooltip),
      title: "出資の種類",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("details2", textColumnWithTooltip),
      title: "証券名",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("volume", createFloatSuffixColumn()),
      title: "数量等",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("volume2", createFloatSuffixColumn()),
      title: "出資金額",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("unit2", textColumnWithTooltip),
      title: "通貨",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn(
        "differencesExistence",
        createSelectColumn({
          choices: differenceChoices,
          disabled: disableResponse,
        })
      ),
      title: "相違の有無",
      disabled: disableResponse,
      grow: 0.5,
    },
    {
      ...keyColumn("volume3", createFloatSuffixColumn()),
      title: "出資金総額",
      disabled: disableResponse,
      grow: 0.8,
    },
    {
      ...keyColumn(
        "details3",
        createSelectColumn({
          choices: presenceOrAbsenceChoices,
          disabled: disableResponse,
        })
      ),
      title: "質権設定の有無",
      disabled: disableResponse,
      grow: 0.5,
    },
    {
      ...keyColumn("details4", textColumnWithTooltip),
      title: "質権者の氏名\n又は名称",
      disabled: disableResponse,
    },
    {
      ...keyColumn("details5", textColumnWithTooltip),
      title: "質権の内容",
      disabled: disableResponse,
      grow: 2,
    },
  ];

  return (
    <>
      <Controller
        name="equity"
        control={control}
        render={({ field }) => (
          <DynamicDataSheetGrid
            onChange={field.onChange}
            value={field.value}
            columns={columns}
            lockRows={disableConfirmation}
            createRow={() => ({
              id: "",
              confirmationLetterId: confirmationLetterId,
              kubun: Constants.OWNED_STOCK_KUBUN.EQUITY,
            })}
            duplicateRow={(rowData) => ({
              ...rowData,
              id: "",
            })}
          />
        )}
      />
    </>
  );
};

export default EquitySpreadsheet;
