/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";
import OwnedStockConfirmation from "../../components/Comfirmation/OwnedStockConfirmation";
import DefaultPaper from "../../components/DefaultPaper";
import ConfirmerForm from "../../components/Form/ConfirmerForm";
import TextFieldWithTooltip from "../../components/Input/TextFieldWithTooltip";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import useApi, { httpClient } from "../../hooks/useApi";
import { messageActions } from "../../store/store";
import { infoTheme } from "../../style/theme";
import getConfirmerListHead, {
  confirmerDataMapper,
} from "../../utils/ConfirmerListHead";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";
// バリデーション定義
const schemaRequest = z.object({
  approvalRequestComment: z
    .string()
    .max(
      Constants.MULTI_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.MULTI_LINE_TEXT_MAX)
    ),
});
const schemaRemand = z.object({
  remandComment: z
    .string()
    .max(
      Constants.MULTI_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.MULTI_LINE_TEXT_MAX)
    ),
});
const ConfirmationLetter = ({ title }) => {
  const params = useParams();
  const { connect } = useApi();
  const [disableEdit, setDisableEdit] = useState(true);
  const [updateConfirmerList, setUpdateConfirmerList] = useState(0);
  const dispatch = useDispatch();
  // 確認状情報取得
  const [fetchResult, setFetchResult] = useState();
  const fetchData = async () => {
    const result = await connect(() => {
      return httpClient.get(`/api/confirmer/GetConfirmation?id=${params.id}`);
    });
    setFetchResult(result?.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  // 表示制御
  const initializeState = () => {
    const confirmationLetterStatus =
      fetchResult?.data?.confirmationLetterStatus;
    const hasEditAuthority =
      fetchResult?.data?.confirmerAuthority ===
        Constants.CONFIRMER_AUTHORITY_TYPE.EDIT ||
      fetchResult?.data?.confirmerAuthority ===
        Constants.CONFIRMER_AUTHORITY_TYPE.EDIT_APPROVAL;
    const hasApprovalAuthority =
      fetchResult?.data?.confirmerAuthority ===
        Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL ||
      fetchResult?.data?.confirmerAuthority ===
        Constants.CONFIRMER_AUTHORITY_TYPE.EDIT_APPROVAL;
    // 承認依頼ボタン
    setRequestButton(
      params.type === "create" &&
        confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS.SHIPPED &&
        hasEditAuthority
    );
    // 承認依頼キャンセルボタン
    setCancelRequestButton(
      params.type === "create" &&
        confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS
            .WAITING_FOR_APPROVAL_OF_CONFIRMER &&
        hasEditAuthority
    );
    // 承認ボタン
    setApprovalButton(
      params.type === "approval" &&
        !fetchResult?.data?.isApproved &&
        hasApprovalAuthority &&
        confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS.WAITING_FOR_APPROVAL_OF_CONFIRMER
    );
    // 承認取り消しボタン
    setCancelApprovalButton(
      params.type === "approval" &&
        !!fetchResult?.data?.isApproved &&
        hasApprovalAuthority &&
        confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS.WAITING_FOR_APPROVAL_OF_CONFIRMER
    );
    // 差し戻しボタン
    setRemandButton(
      params.type === "approval" &&
        hasApprovalAuthority &&
        confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS.WAITING_FOR_APPROVAL_OF_CONFIRMER
    );
    // 編集可否制御
    setDisableEdit(
      confirmationLetterStatus !==
        Constants.CONFIRMATION_LETTER_STATUS.SHIPPED || !hasEditAuthority
    );
    setDisableConfirmerList(
      confirmationLetterStatus !==
        Constants.CONFIRMATION_LETTER_STATUS.SHIPPED ||
        params.type === "approval"
    );
  };
  useEffect(() => initializeState(), [params, fetchResult]);

  // 確認依頼文関連
  const [requestStatementReplaced, setRequestStatementReplaced] = useState();
  const [confirmerList, setConfirmerList] = useState();
  useEffect(() => {
    let atena = fetchResult?.data?.confirmationDestination + "\n";
    atena =
      atena +
      confirmerList
        ?.filter((row) => row.isCreatedByAuditorOrClient)
        ?.map((row) => row.department + " " + row.name + "様")
        .join("\n");
    let tempStatement = fetchResult?.data?.requestStatement?.replace(
      /#{宛名}/g,
      atena
    );
    tempStatement = tempStatement?.replace(
      /#{基準日}/g,
      fetchResult?.data?.referenceDateFormatted
    );
    tempStatement = tempStatement?.replace(
      /#{被監査企業}/g,
      fetchResult?.data?.companyName
    );
    setRequestStatementReplaced(tempStatement);
  }, [fetchResult, confirmerList]);

  //　承認申請ダイアログ関連
  const [requestButton, setRequestButton] = useState(false);
  const confirmationDataRef = useRef();
  const {
    control: requestControl,
    handleSubmit: requestHandleSubmit,
    formState: { errors: requestErrors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schemaRequest),
  });
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);
  // const [saveResult, setSaveResult] = useState();
  const handleRequest = async (data) => {
    // 入力内容のバリデーションチェック
    const validationResult = await confirmationDataRef?.current?.validate();
    if (!validationResult) {
      dispatch(messageActions.setError(MESSAGES.VALIDATION_ERROR));
      setRequestDialogOpen(false);
      return;
    }
    // 入力内容の保存
    const saveResult = await connect(() => {
      return httpClient.post(
        `/api/confirmer/UpsertConfirmation`,
        confirmationDataRef?.current?.getFormData()
      );
    });
    if (saveResult?.status !== 200) return;
    // 承認依頼処理
    await connect(() => {
      return httpClient.post("/api/confirmer/RequestApproval", {
        confirmationLetterId: params.id,
        approvalRequestComment: data?.approvalRequestComment,
      });
    }, "承認依頼を送信しました");
    fetchData();
    setRequestDialogOpen(false);
  };
  //　承認依頼取り下げダイアログ関連
  const [cancelRequestButton, setCancelRequestButton] = useState(false);
  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const handleWithdraw = async () => {
    await connect(() => {
      return httpClient.post("/api/confirmer/CancelApprovalRequest", {
        confirmationLetterId: params.id,
      });
    }, "承認依頼を取り下げました");
    setWithdrawDialogOpen(false);
    setUpdateConfirmerList(updateConfirmerList + 1);
    fetchData();
  };
  //　承認関連
  const [approvalButton, setApprovalButton] = useState(false);
  const handleApprove = async () => {
    await connect(() => {
      return httpClient.post("/api/confirmer/Approve", {
        confirmationLetterId: params.id,
      });
    }, "承認しました");
    fetchData();
    setUpdateConfirmerList(updateConfirmerList + 1);
  };
  //　承認取り消し関連
  const [cancelApprovalButton, setCancelApprovalButton] = useState(false);
  const handleCancelApproval = async () => {
    await connect(() => {
      return httpClient.post("/api/confirmer/CancelApproval", {
        confirmationLetterId: params.id,
      });
    }, "承認を取り消しました");
    fetchData();
    setUpdateConfirmerList(updateConfirmerList + 1);
  };
  //　申請差し戻しダイアログ
  const [remandDialogOpen, setRemandDialogOpen] = useState(false);
  const [remandButton, setRemandButton] = useState(false);
  const {
    control: remandControl,
    handleSubmit: remandHandleSubmit,
    formState: { errors: remandErrors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schemaRemand),
  });
  const handleRemand = async (data) => {
    await connect(() => {
      return httpClient.post("/api/confirmer/RemandApprovalRequest", {
        confirmationLetterId: params.id,
        remandComment: data?.remandComment,
      });
    }, "承認依頼を差し戻しました");
    fetchData();
    setRemandDialogOpen(false);
    setUpdateConfirmerList(updateConfirmerList + 1);
  };

  // 担当者一覧関連
  const [disableConfirmerList, setDisableConfirmerList] = useState(true);

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle1" sx={{ whiteSpace: "pre-wrap" }}>
        {requestStatementReplaced}
      </Typography>
      {params.type === "approval" && (
        <Typography variant="subtitle1">
          【承認者の方へ】
          <br />
          回答内容を確認いただき、問題なければ承認してください。
          <br />
          修正がある場合は承認申請の差し戻しを行ってください。
          <br />
          また全ての承認者の方が承認すると確認状の回答は確定となります。
          <br />
          承認者が複数名設定されている場合は、全ての方が承認を行ってください。
        </Typography>
      )}

      {/* メニュー */}
      <DefaultPaper title={"メニュー"}>
        {/* marginright必要？ */}
        <Box>
          {requestButton && (
            <Button onClick={() => setRequestDialogOpen(true)}>承認依頼</Button>
          )}
          {cancelRequestButton && (
            <Button onClick={() => setWithdrawDialogOpen(true)} color="warning">
              承認依頼の取り下げ
            </Button>
          )}
          {cancelApprovalButton && (
            <Button color="warning" onClick={handleCancelApproval}>
              承認の取り消し
            </Button>
          )}
          {approvalButton && <Button onClick={handleApprove}>承認</Button>}
          {remandButton && (
            <Button onClick={() => setRemandDialogOpen(true)} color="warning">
              申請の差し戻し
            </Button>
          )}
        </Box>
        <ThemeProvider theme={infoTheme}>
          {params.type === "create" && !!fetchResult?.data?.remandComment && (
            <TextField
              multiline
              label="差し戻し理由"
              value={fetchResult?.data?.remandComment}
              minRows={2}
              maxRows={6}
              fullWidth
              sx={{ marginBottom: "1rem" }}
            />
          )}
          {params.type === "approval" &&
            !!fetchResult?.data?.approvalRequestComment && (
              <TextField
                multiline
                label="承認申請者コメント"
                value={fetchResult?.data?.approvalRequestComment}
                minRows={2}
                maxRows={6}
                fullWidth
                sx={{ marginBottom: "1rem" }}
              />
            )}
        </ThemeProvider>
        <Typography variant="body1">担当者一覧</Typography>
        <AddEditDialogTable
          getPath={`/api/confirmer/GetConfirmerList?confirmationLetterId=${params.id}`}
          deletePath="/api/confirmer/DeleteConfirmer"
          headCells={getConfirmerListHead()}
          rowMapper={confirmerDataMapper}
          listName={"確認担当者"}
          editDialogContent={ConfirmerForm}
          dialogProps={{ confirmationLetterId: params.id }}
          disablePagination
          disableAdd={disableConfirmerList}
          disableDelete={disableConfirmerList}
          disableEdit={disableConfirmerList}
          _setRows={setConfirmerList}
          forceUpdate={updateConfirmerList}
          postSubmitHandler={fetchData}
        ></AddEditDialogTable>
      </DefaultPaper>

      {/* 概要 */}
      <DefaultPaper title="確認状概要">
        <ThemeProvider theme={infoTheme}>
          <Grid container>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="被監査企業"
                value={fetchResult?.data?.companyName}
              ></TextFieldWithTooltip>
            </Grid>
            <Grid item xs={9} display="flex" alignItems="flex-end">
              <TextFieldWithTooltip
                label="回収希望日"
                value={fetchResult?.data?.desiredCollectionDateFormatted}
              />
              <Typography>までにご回答をお願い致します。</Typography>
            </Grid>
          </Grid>
        </ThemeProvider>
      </DefaultPaper>

      {/* 確認内容 */}
      <DefaultPaper title={"確認内容"}>
        {/* 所有株式 */}
        {fetchResult?.data?.confirmationType ===
          Constants.CONFIRMATION_LETTER_TYPE.OWNED_STOCK && (
          <OwnedStockConfirmation
            targetData={fetchResult?.data}
            presenceOrAbsenceList={fetchResult?.presenceOrAbsenceList}
            postSaveHandler={fetchData}
            readOnly={disableEdit}
            ref={confirmationDataRef}
          />
        )}
      </DefaultPaper>

      {/* 承認依頼ダイアログ */}
      <Dialog
        open={requestDialogOpen}
        onClose={() => setRequestDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={requestHandleSubmit(handleRequest)}>
          <DialogContent>
            <Box padding={"0.25rem"}>
              <Controller
                name="approvalRequestComment"
                control={requestControl}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="コメント"
                    {...field}
                    multiline
                    minRows={5}
                    maxRows={5}
                    sx={{ width: "90%" }}
                    error={!!requestErrors.approvalRequestComment}
                    helperText={
                      requestErrors.approvalRequestComment
                        ? requestErrors.approvalRequestComment.message
                        : ""
                    }
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit">申請</Button>
            <Button onClick={() => setRequestDialogOpen(false)} color="warning">
              キャンセル
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* 取り下げダイアログ */}
      <Dialog
        open={withdrawDialogOpen}
        onClose={() => setWithdrawDialogOpen(false)}
      >
        <DialogContent>
          <Box padding={"0.25rem"}>
            承認依頼を取り下げます
            <br />
            よろしいですか？
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWithdraw}>取り下げ</Button>
          <Button onClick={() => setWithdrawDialogOpen(false)} color="warning">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>

      {/* 申請差し戻しダイアログ */}
      <Dialog
        open={remandDialogOpen}
        onClose={() => setRemandDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={remandHandleSubmit(handleRemand)}>
          <DialogContent>
            <Box padding={"0.25rem"}>
              <Controller
                name="remandComment"
                control={remandControl}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="差し戻し理由"
                    {...field}
                    multiline
                    minRows={5}
                    maxRows={5}
                    sx={{ width: "90%" }}
                    error={!!remandErrors.remandComment}
                    helperText={
                      remandErrors.remandComment
                        ? remandErrors.remandComment.message
                        : ""
                    }
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit">差し戻し</Button>
            <Button onClick={() => setRemandDialogOpen(false)} color="warning">
              キャンセル
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ConfirmationLetter;
