export const MESSAGES = {
  REQUIRED: "必須項目です",
  POST_CODE_ERROR: "半角数字、ハイフン付きで入力してください",
  TEL_ERROR: "電話番号が不正です",
  EMAIL_ERROR: "メールアドレスが不正です",
  TEXT_MAX_ERROR: (max) => `${max}文字以内で入力してください`,
  REQUIRED_ERROR_WITH_NAME: (field) => `${field}は必須項目です`,
  VALIDATION_ERROR: "入力内容が不正なため保存できませんでした",
};

export default MESSAGES;
