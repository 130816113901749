import { Outlet } from "react-router-dom";
import Layout from "../../components/Layout";

function Index() {
  const headerLinkList = [
    {
      href: "company/new",
      text: "クライアントの新規作成",
    },
  ];
  const settingLinkList = [
    // {
    //   href: "/.auth/logout",
    //   text: "ログアウト",
    // },
  ];
  return (
    <Layout headerLinkList={headerLinkList} settingLinkList={settingLinkList}>
      <Outlet />
    </Layout>
  );
}

export default Index;
