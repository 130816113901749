/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";
// バリデーション定義
const schema = z.object({
  id: z.string(),
  procedureId: z.string().min(1, MESSAGES.REQUIRED),
  confirmationType: z.string().min(1, MESSAGES.REQUIRED),
  referNo: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  desiredCollectionDate: z.string().nullable(),
  confirmationDestination: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  postCode: z
    .string()
    .regex(Constants.POST_CODE_REGEXP, MESSAGES.POST_CODE_ERROR)
    .or(z.literal("")),
  prefectureCode: z.string(),
  cityWardTownVillage: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  restAddress: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  addressEnglish: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  sendingMethod: z.string().min(1, MESSAGES.REQUIRED),
});

const ConfirmationLetterForm = ({
  targetData = {},
  procedureId,
  confirmationType,
  prefectureList,
  sendingMethodList,
  closeHandler,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();
  const [disableAddressAutofill, setDisableAddressAutofill] = useState(false);

  // 新規作成時にフォームをクリア
  useEffect(() => {
    if (Object.keys(targetData).length !== 0) {
      reset({
        ...targetData,
        desiredCollectionDate:
          targetData?.desiredCollectionDateFormatted?.replace(/\//g, "-"),
        confirmationType: confirmationType,
      });
    } else {
      reset({
        id: "",
        procedureId: procedureId,
        confirmationType: confirmationType,
        referNo: "",
        desiredCollectionDate: null,
        confirmationDestination: "",
        postCode: "",
        prefectureCode: "",
        cityWardTownVillage: "",
        restAddress: "",
        addressEnglish: "",
        sendingMethod: Constants.SENDING_METHOD.EMAIL_OR_POST,
      });
      setDisableAddressAutofill(true);
    }
  }, [targetData]);
  // 住所の自動入力
  const postCodeWatch = watch("postCode");
  useEffect(() => {
    // 初期表示時は住所取得を行わない
    if (postCodeWatch === targetData?.postCode && !isDirty) {
      return;
    }
    if (postCodeWatch && !errors.postCode && postCodeWatch.length === 8) {
      const fetchAddress = async () => {
        try {
          const response = await connect(() => {
            return httpClient.get(
              `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCodeWatch}`
            );
          });
          console.log("response", response);
          const data = response.data.results[0];

          if (data) {
            // 都道府県、市区町村、町名をフォームにセット
            setValue("prefectureCode", data.prefcode); // 都道府県
            setValue("cityWardTownVillage", data.address2); // 市区町村
            setValue("restAddress", data.address3); // 町名
          }
        } catch (error) {
          console.error("住所の取得に失敗しました", error);
        }
      };

      fetchAddress();
    }
  }, [postCodeWatch, errors.postCode, setValue]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/UpsertConfirmation", {
        ...data,
        desiredCollectionDate: !data?.desiredCollectionDate
          ? null
          : data?.desiredCollectionDate,
      });
    }, "保存しました");
    // TODO:開発用
    console.log("post end", data);
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="referNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  error={!!errors.referNo}
                  helperText={errors.referNo ? errors.referNo.message : ""}
                  label="リファー番号"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="desiredCollectionDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="回収希望日"
                  type="date"
                  {...field}
                  error={!!errors.desiredCollectionDate}
                  helperText={
                    errors.desiredCollectionDate
                      ? errors.desiredCollectionDate.message
                      : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="confirmationDestination"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  error={!!errors.confirmationDestination}
                  helperText={
                    errors.confirmationDestination
                      ? errors.confirmationDestination.message
                      : ""
                  }
                  label="相手先名"
                />
              )}
            />
            <FormControl sx={{ minWidth: "5rem" }}>
              <InputLabel>都道府県</InputLabel>
              <Controller
                name="prefectureCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    error={!!errors.prefectureCode}
                    helperText={
                      errors.prefectureCode ? errors.prefectureCode.message : ""
                    }
                  >
                    {prefectureList?.map((prefecture) => (
                      <MenuItem value={prefecture.code} key={prefecture.code}>
                        {prefecture.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="postCode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="郵便番号"
                  {...field}
                  error={!!errors.postCode}
                  helperText={errors.postCode ? errors.postCode.message : ""}
                />
              )}
            />
            <Controller
              name="cityWardTownVillage"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="市区町村"
                  {...field}
                  error={!!errors.cityWardTownVillage}
                  helperText={
                    errors.cityWardTownVillage
                      ? errors.cityWardTownVillage.message
                      : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="restAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="番地・ビル名など"
                  fullWidth
                  {...field}
                  error={!!errors.restAddress}
                  helperText={
                    errors.restAddress ? errors.restAddress.message : ""
                  }
                />
              )}
            />
            <Controller
              name="addressEnglish"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="Address（English）"
                  fullWidth
                  {...field}
                  error={!!errors.addressEnglish}
                  helperText={
                    errors.addressEnglish ? errors.addressEnglish.message : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: "5rem" }}>
              <InputLabel required>送付方法</InputLabel>
              <Controller
                name="sendingMethod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    error={!!errors.sendingMethod}
                    helperText={
                      errors.sendingMethod ? errors.sendingMethod.message : ""
                    }
                  >
                    {sendingMethodList?.map((row) => (
                      <MenuItem value={row.code} key={row.code}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {/* <FormControlLabel
            control={
              <Switch
                checked={sendRemainder}
                onChange={(e) => setSendRemainder(e.target.checked)}
              />
            }
            label="催告メールを送る"
          /> */}
          </Grid>
          <Grid item xs={6}>
            {/* {sendRemainder && (
            <TextField
              label="期限切れ後の催告メールの送信タイミング"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">日後</InputAdornment>
                ),
              }}
            />
          )} */}
          </Grid>
          {/* <Grid item xs={6}>
          <TextField type="date" label="対象期間（開始）" />
          <TextField type="date" label="監査証明日（会社法）" />
          <TextField type="date" label="対象会社決算日" />
        </Grid>
        <Grid item xs={6}>
          <TextField type="date" label="対象期間（終了）" />
          <TextField type="date" label="監査証明日（金融商品取引法）" />
        </Grid> */}
        </Grid>
        {/* <br />
      <FormControl minWidth={"20rem"}>
        <InputLabel>確認状種別</InputLabel>
        <Select
        // labelId="demo-simple-select-label"
        // id="demo-simple-select"
        // value={age}
        // label="Age"
        // onChange={handleChange}
        >
          <MenuItem value={"RECEIVABLES_DEBTS"}>債権債務</MenuItem>
          <MenuItem value={"LWAYER"}>弁護士</MenuItem>
          <MenuItem value={"INVENTORY_ON_HOLD"}>預り在庫</MenuItem>
          <MenuItem value={"OWNED_STOCK"}>所有株式</MenuItem>
          <MenuItem value={"SECURITIES_TRADING"}>証券取引</MenuItem>
          <MenuItem value={"LIFE_INSURANCE"}>生命保険</MenuItem>
          <MenuItem value={"LEASE"}>リース</MenuItem>
          <MenuItem value={"RETIREMENT_BENEFIT_INSURANCE"}>
            退職給付年金資産(生保)
          </MenuItem>
          <MenuItem value={"RETIREMENT_BENEFIT_BANK"}>
            退職給付年金資産(信銀)
          </MenuItem>
          <MenuItem value={"REAL_ESTATE_LEASE_AGREEMENT"}>
            不動産賃貸借契約
          </MenuItem>
          <MenuItem value={"LOAN_AGREEMENT"}>借入契約</MenuItem>
          <MenuItem value={"OTHERS"}>その他</MenuItem>
        </Select>
      </FormControl> */}
        {/* <br />
      <FormControl minWidth={"20rem"}>
        <InputLabel>言語</InputLabel>
        <Select>
          <MenuItem value={"JAPANASE"}>日本語</MenuItem>
          <MenuItem value={"ENGLISH"}>英語</MenuItem>
        </Select>
      </FormControl> */}

        <Button type="submit">保存</Button>
      </form>
    </>
  );
};

export default ConfirmationLetterForm;
