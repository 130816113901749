/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@emotion/react";
import {
  CloudDownload,
  CloudUpload,
  PictureAsPdf,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import React, { useEffect, useState } from "react";
import { FaFileExcel, FaFileWord } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link as domLink, useParams } from "react-router-dom";
import DefaultPaper from "../../components/DefaultPaper";
import ConfirmationLetterForm from "../../components/Form/ConfirmationLetterForm";
import MailForm from "../../components/Form/MailForm";
import MessageForm from "../../components/Form/MessageForm";
import ProcedureForm from "../../components/Form/ProcedureForm";
import FormDialog from "../../components/FormDialog";
import TextFieldWithTooltip from "../../components/Input/TextFieldWithTooltip";
import ClientAuthoritylSpreadsheet from "../../components/SpreadSheets/ClientAuthoritySpreadsheet";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import useApi, { httpClient } from "../../hooks/useApi";
import { infoTheme } from "../../style/theme";
import getBulkRegisterListHead from "../../utils/BulkRegisterListHead";
import getConfirmationLetterListHead, {
  confirmationLetterDataMapper,
} from "../../utils/ConfirmationLetterListHead";
import Constants from "../../utils/Constants";
import getMessageListHead from "../../utils/MessageListHead";

const Procedure = ({ title }) => {
  const params = useParams();
  const { connect } = useApi();
  const role = useSelector((state) => state.role.role);
  const [disableEdit, setDisableEdit] = useState(true);

  // 手続情報取得
  const [fetchResult, setFetchResult] = useState();
  const fetchData = async () => {
    if (!role) return;
    const result = await connect(() => {
      return httpClient.get(`../../api/${role}/GetProcedure?id=${params.id}`);
    });
    setFetchResult(result?.data);
  };
  useEffect(() => {
    fetchData();
  }, [role]);

  // 表示制御
  const initializeState = () => {
    const procedureStatus = fetchResult?.data?.procedureStatus;
    const hasEditAuthority =
      fetchResult?.data?.authority === Constants.CLIENT_AUTHORITY_TYPE.EDIT ||
      fetchResult?.data?.authority ===
        Constants.CLIENT_AUTHORITY_TYPE.EDIT_APPROVAL;
    const hasApprovalAuthority =
      fetchResult?.data?.authority ===
        Constants.CLIENT_AUTHORITY_TYPE.APPROVAL ||
      fetchResult?.data?.authority ===
        Constants.CLIENT_AUTHORITY_TYPE.EDIT_APPROVAL;
    // 「送付先・確認状の登録依頼」ボタンの表示制御
    setRequestRegisterDialogOpenButton(
      procedureStatus === Constants.PROCECURE_STATUS.REGISTERED ||
        procedureStatus === Constants.PROCECURE_STATUS.WAITING_FOR_APPROVAL ||
        procedureStatus === Constants.PROCECURE_STATUS.APPROVED
    );
    // 「承認の差し戻し」ボタンの表示制御
    setRemandApprovalButton(role === Constants.ROLE.AUDITOR);
    // 「承認」ボタンの表示制御
    setApprovalButton(
      role === Constants.ROLE.CLIENT &&
        hasApprovalAuthority &&
        fetchResult?.data?.isApproved === false &&
        (procedureStatus === Constants.PROCECURE_STATUS.REGISTERED ||
          procedureStatus === Constants.PROCECURE_STATUS.WAITING_FOR_APPROVAL)
    );
    setDisableApprovalButton(
      fetchResult?.data?.isAuthorityDetermined === false
    );
    // 「承認の取り下げ」ボタンの表示制御
    setCancelApprovalButton(
      role === Constants.ROLE.CLIENT &&
        hasApprovalAuthority &&
        fetchResult?.data?.isApproved === true &&
        (procedureStatus === Constants.PROCECURE_STATUS.WAITING_FOR_APPROVAL ||
          procedureStatus === Constants.PROCECURE_STATUS.APPROVED)
    );
    // 「確認依頼メールの一括送付」ボタンの表示制御
    setSendConfirmationButton(
      role === Constants.ROLE.AUDITOR &&
        (procedureStatus === Constants.PROCECURE_STATUS.APPROVED ||
          procedureStatus === Constants.PROCECURE_STATUS.STARTED)
    );
    // 「確認状ファイル一括出力」ボタンの表示制御
    setDlConfirmationButton(
      role === Constants.ROLE.AUDITOR &&
        (procedureStatus === Constants.PROCECURE_STATUS.APPROVED ||
          procedureStatus === Constants.PROCECURE_STATUS.STARTED)
    );
    // 「手続の結了」ボタンの表示制御
    setCompleteButton(
      role === Constants.ROLE.AUDITOR &&
        procedureStatus === Constants.PROCECURE_STATUS.STARTED
    );
    // 「手続結了の取り消し」ボタンの表示制御
    setUncompleteButton(
      role === Constants.ROLE.AUDITOR &&
        procedureStatus === Constants.PROCECURE_STATUS.CLOSED
    );
    // 編集可否制御
    setDisableEdit(
      procedureStatus !== Constants.PROCECURE_STATUS.REGISTERED ||
        (role === Constants.ROLE.CLIENT && !hasEditAuthority)
    );
  };
  useEffect(() => initializeState(), [role, fetchResult]);

  // 送付先・確認状の登録依頼関連
  const [requestRegisterDialogOpenButton, setRequestRegisterDialogOpenButton] =
    useState(false);
  const [requestRegisterDialogOpen, setRequestRegisterDialogOpen] =
    useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [messageDialogTitle, setMessageDialogTitle] = useState();
  const [updateMessageTable, setUpdateMessageTable] = useState(0);
  const handleCloseMessageDialog = (result) => {
    if (result?.status === 200) {
      setMessageDialogOpen(false);
      setUpdateMessageTable(updateMessageTable + 1);
    }
  };

  //承認の差し戻し関連
  const [remandApprovalButton, setRemandApprovalButton] = useState(false);
  const [remandApprovalDialogOpen, setRemandApprovalDialogOpen] =
    useState(false);
  const remandApproval = async () => {
    await connect(() => {
      return httpClient.post("../../api/auditor/RemandApproval", {
        id: params.id,
      });
    }, "承認を差し戻しました");
    setRemandApprovalDialogOpen(false);
    fetchData();
  };
  // 「承認」ボタン関連
  const [approvalButton, setApprovalButton] = useState(false);
  const [disableApprovalButton, setDisableApprovalButton] = useState(false);
  const approve = async () => {
    await connect(() => {
      return httpClient.post("../../api/client/ApproveProcedure", {
        procedureId: params.id,
      });
    }, "承認しました");
    fetchData();
  };
  //承認の取り下げ関連
  const [cancelApprovalButton, setCancelApprovalButton] = useState(false);
  const [cancelApprovalDialogOpen, setCancelApprovalDialogOpen] =
    useState(false);
  const cancelApproval = async () => {
    await connect(() => {
      return httpClient.post("../../api/client/CancelProcedureApproval", {
        procedureId: params.id,
      });
    }, "承認を取り下げました");
    fetchData();
    setCancelApprovalDialogOpen(false);
  };

  // 確認依頼メール一括送付関連
  const [mailDialogOpen, setMailDialogOpen] = useState(false);
  const [sendConfirmationButton, setSendConfirmationButton] = useState(false);
  const handleCloseMailDialog = () => {
    setMailDialogOpen(false);
  };
  const handleSendConfirmationLetter = (result) => {
    if (result?.status === 200) {
      setMailDialogOpen(false);
      fetchData();
    }
  };
  // 確認状ファイル一括出力関連
  const [dlConfirmationButton, setDlConfirmationButton] = useState(false);
  // 手続の結了関連
  const [completeButton, setCompleteButton] = useState(false);
  const completeProcedure = async () => {
    await connect(() => {
      return httpClient.post(
        `/api/auditor/CompleteProcedure?id=${params.id}&complete=1`
      );
    }, "結了しました");
    fetchData();
  };
  // 手続結了の取り消し関連
  const [uncompleteButton, setUncompleteButton] = useState(false);
  const uncompleteProcedure = async () => {
    await connect(() => {
      return httpClient.post(
        `/api/auditor/CompleteProcedure?id=${params.id}&complete=0`
      );
    }, "結了を取り消しました");
    fetchData();
  };

  //手続き編集関連
  const [procedureEditDialogOpen, setProcedureEditDialogOpen] = useState(false);
  const handleCloseProcedureEdit = (result) => {
    if (result?.status === 200) {
      setProcedureEditDialogOpen(false);
      fetchData();
    }
  };

  //確認状一括登録関連
  const [confirmLetterEditDialogOpen, setConfirmationLetterEditDialogOpen] =
    useState(false);
  const [bulkRegisterResultTable, setBulkRegisterResultTable] = useState();
  const [bulkRegisterResult, setBulkRegisterResult] = useState();
  const [updateConfirmationTable, setUpdateConfirmationTable] = useState(0);
  const [csfile, setCsfile] = useState(null);
  const handleUploadCsfile = async () => {
    const result = await connect(() => {
      return httpClient.post(
        `/api/${role}/UploadConfirmationLetterCSFormat?proceureId=${params.id}`,
        csfile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    });
    if (result?.status === 200) {
      setUpdateConfirmationTable(updateConfirmationTable + 1);
      setBulkRegisterResultTable(true);
      setBulkRegisterResult(result?.data);
      setCsfile(null);
    }
  };

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      {/* メニュー */}
      <DefaultPaper title={"メニュー"}>
        <Box sx={{ ".MuiTextField-root": { marginRight: "1rem" } }}>
          <ThemeProvider theme={infoTheme}>
            <TextFieldWithTooltip
              label="ステータス"
              value={fetchResult?.data?.procedureStatusLabel}
            />
          </ThemeProvider>
          {requestRegisterDialogOpenButton && (
            <Button onClick={() => setRequestRegisterDialogOpen(true)}>
              送付先・確認状の登録依頼
            </Button>
          )}
          {remandApprovalButton && (
            <Button
              color="warning"
              onClick={() => setRemandApprovalDialogOpen(true)}
            >
              承認の差し戻し
            </Button>
          )}
          {approvalButton && (
            <Tooltip
              title={
                disableApprovalButton
                  ? "承認する前に担当者権限の確定を行ってください"
                  : ""
              }
            >
              <span>
                <Button
                  disabled={disableApprovalButton}
                  onClick={() => approve()}
                >
                  承認
                </Button>
              </span>
            </Tooltip>
          )}
          {cancelApprovalButton && (
            <Button
              onClick={() => setCancelApprovalDialogOpen(true)}
              color="warning"
            >
              承認の取り下げ
            </Button>
          )}
          <br />
          {sendConfirmationButton && (
            <Button
              startIcon={<Send />}
              onClick={() => setMailDialogOpen(true)}
            >
              確認依頼メール一括送付
            </Button>
          )}
          {dlConfirmationButton && (
            <Button
              download
              href={`../../api/auditor/DownloadConfirmationLetter?procedureId=${params.id}`}
              startIcon={<FaFileWord />}
            >
              確認状ファイル一括出力
            </Button>
          )}
          {role === Constants.ROLE.AUDITOR && (
            <Button
              href={
                `/api/auditor/DownloadConfirmationResultExcelForProcedure?` +
                `procedureId=${params.id}&` +
                `confirmationLetterType=${fetchResult?.data?.confirmationType}`
              }
              download
              startIcon={<FaFileExcel />}
            >
              確認結果一覧エクセル出力
            </Button>
          )}
          {false && (
            <Button startIcon={<PictureAsPdf />}>監査調書PDF一括出力</Button>
          )}
          {false && (
            <Button startIcon={<FaFileExcel />}>集計結果エクセル出力</Button>
          )}
          {completeButton && (
            <Button color="warning" onClick={() => completeProcedure()}>
              手続の結了
            </Button>
          )}
          {uncompleteButton && (
            <Button color="warning" onClick={() => uncompleteProcedure()}>
              手続結了の取り消し
            </Button>
          )}
        </Box>
      </DefaultPaper>

      {/* 概要 */}
      <DefaultPaper title="手続き概要">
        <Grid container>
          <ThemeProvider theme={infoTheme}>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="会社コード"
                value={fetchResult?.data?.companyCode}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>被監査企業</InputLabel>
              <Link
                color="inherit"
                noWrap
                to={`/${role}/company/${fetchResult?.data?.companyId}`}
                padding="16.5px 14px"
                component={domLink}
                sx={{
                  color: "#3f51b5",
                }}
              >
                {fetchResult?.data?.companyName}
              </Link>
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="対象期間"
                value={fetchResult?.data?.targetPeriodTitle}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="確認手続名称"
                value={fetchResult?.data?.procedureName}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="確認状様式"
                value={fetchResult?.data?.confirmationTypeLabel}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="承認種別"
                value={fetchResult?.data?.approvalTypeLabel}
              />
            </Grid>
          </ThemeProvider>
          <Grid item xs={2} textAlign={"right"}>
            <Button onClick={() => setProcedureEditDialogOpen(true)}>
              編集
            </Button>
          </Grid>
        </Grid>
      </DefaultPaper>

      {/* 会社担当者一覧 */}
      <DefaultPaper title={"会社担当者一覧"}>
        <ClientAuthoritylSpreadsheet
          targetData={fetchResult?.companyPersonneList}
          clientAuthorityTypeList={fetchResult?.clientAuthorityTypeList}
          isDetermined={fetchResult?.data?.isAuthorityDetermined}
          postSubmitHandler={fetchData}
          procedureId={params.id}
        />
      </DefaultPaper>

      {/* 確認状一覧 */}
      <DefaultPaper title={"確認状一覧"}>
        <Grid container>
          <Button
            onClick={() => setConfirmationLetterEditDialogOpen(true)}
            disabled={disableEdit}
          >
            確認状一括登録
          </Button>

          <Grid item xs={12}>
            <AddEditDialogTable
              getPath={`../../api/auditor/GetConfirmationList?proceureId=${params.id}`}
              deletePath="../../api/auditor/DeleteConfirmation"
              headCells={getConfirmationLetterListHead()}
              rowMapper={confirmationLetterDataMapper}
              listName={"確認状"}
              editDialogContent={ConfirmationLetterForm}
              forceUpdate={updateConfirmationTable}
              dialogProps={{
                procedureId: params.id,
                confirmationType: fetchResult?.data?.confirmationType,
              }}
              disableAdd={disableEdit}
              disableEdit={disableEdit}
              disableDelete={disableEdit}
            ></AddEditDialogTable>
          </Grid>
        </Grid>

        {/* 確認状一括登録ダイアログ */}
        <FormDialog
          open={confirmLetterEditDialogOpen}
          title={"確認状の一括登録"}
          onClose={() => setConfirmationLetterEditDialogOpen(false)}
        >
          <Button
            href="/api/auditor/DownloadConfirmationLetterCSFormat"
            download
            startIcon={<CloudDownload />}
          >
            CSフォーマットのダウンロード
          </Button>
          <Divider sx={{ margin: "0.5rem" }} />
          <form>
            <MuiFileInput
              value={csfile}
              onChange={(file) => setCsfile(file)}
              placeholder="ファイルを選択してください"
            />
            <Button
              onClick={handleUploadCsfile}
              startIcon={<CloudUpload />}
              disabled={!csfile}
            >
              一括登録
            </Button>
          </form>
          {bulkRegisterResultTable && (
            <>
              {/* <Card sx={{ paddingLeft: "1rem" }}> */}
              <Typography>登録結果</Typography>
              <AddEditDialogTable
                headCells={getBulkRegisterListHead()}
                defaultRows={bulkRegisterResult ?? []}
                disablePagination
                disableAdd
                disableDelete
                disableEdit
                disableDialog
              ></AddEditDialogTable>
              {/* </Card> */}
            </>
          )}
        </FormDialog>
      </DefaultPaper>

      {/* 手続情報確認依頼ダイアログ */}
      <Dialog
        open={requestRegisterDialogOpen}
        onClose={() => setRequestRegisterDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle align="center">送付先・確認状の登録依頼</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setMessageDialogOpen(true);
                setMessageDialogTitle("依頼");
              }}
            >
              依頼
            </Button>
            <Button
              onClick={() => {
                setMessageDialogOpen(true);
                setMessageDialogTitle("回答");
              }}
            >
              回答
            </Button>
          </DialogActions>
          <Card sx={{ paddingLeft: "1rem" }}>
            <AddEditDialogTable
              getPath={`../../api/auditor/GetMessageList?referenceRecordId=${params.id}`}
              headCells={getMessageListHead()}
              forceUpdate={updateMessageTable}
              disablePagination
              disableAdd
              disableDelete
              disableEdit
              disableDialog
            ></AddEditDialogTable>
          </Card>
        </DialogContent>
      </Dialog>
      {/* 確認依頼メッセージダイアログ */}
      <FormDialog
        open={messageDialogOpen}
        title={messageDialogTitle}
        onClose={() => setMessageDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <MessageForm
          targetData={{
            referenceEntity: Constants.TABLES.PROCEDURE,
            referenceRecordId: params?.id,
            messageType: Constants.MESSAGE_TYPE.PROCEDURE_INFO,
            messageClassification:
              messageDialogTitle === "依頼"
                ? Constants.MESSAGE_CLASSIFICATION.REQUEST
                : Constants.MESSAGE_CLASSIFICATION.RESPONSE,
            senderRole: role?.toUpperCase(),
          }}
          closeHandler={handleCloseMessageDialog}
        />
      </FormDialog>
      {/* 承認差し戻しダイアログ */}
      <Dialog
        maxWidth="xs"
        open={remandApprovalDialogOpen}
        onClose={() => setRemandApprovalDialogOpen(false)}
      >
        <DialogContent>
          クライアントによる承認をすべて取り消します。
          ※この操作は元に戻せません。
        </DialogContent>
        <DialogActions>
          <Button onClick={() => remandApproval()}>実行</Button>
          <Button onClick={() => setRemandApprovalDialogOpen(false)}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      {/* 承認取り下げダイアログ */}
      <Dialog
        maxWidth="xs"
        open={cancelApprovalDialogOpen}
        onClose={() => setCancelApprovalDialogOpen(false)}
      >
        <DialogContent>承認を取り下げます。よろしいですか？</DialogContent>
        <DialogActions>
          <Button onClick={() => cancelApproval()}>実行</Button>
          <Button onClick={() => setCancelApprovalDialogOpen(false)}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      {/* メール送信ダイアログ */}
      <FormDialog
        open={mailDialogOpen}
        title="確認依頼メールの一括送信"
        onClose={handleCloseMailDialog}
        fullWidth
        maxWidth="md"
      >
        <MailForm
          procedureId={params.id}
          closeHandler={handleSendConfirmationLetter}
        />
      </FormDialog>

      {/* 手続情報編集ダイアログ */}
      <FormDialog
        open={procedureEditDialogOpen}
        title={"手続情報の編集"}
        onClose={() => setProcedureEditDialogOpen(false)}
      >
        <ProcedureForm
          targetData={fetchResult?.data}
          {...fetchResult}
          companyList={[
            {
              value: fetchResult?.data?.companyId,
              label: fetchResult?.data?.companyCode,
            },
          ]}
          closeHandler={handleCloseProcedureEdit}
        />
      </FormDialog>
    </>
  );
};

export default Procedure;
