import { Outlet } from "react-router-dom";
import Layout from "../../components/Layout";

function Index() {
  const settingLinkList = [
    // {
    //   href: "/.auth/logout",
    //   text: "ログアウト",
    // },
  ];
  return (
    <>
      <Layout settingLinkList={settingLinkList}>
        {/* <ScrollRestoration /> */}
        <Outlet />
      </Layout>
    </>
  );
}
export default Index;
