/* eslint-disable react-hooks/exhaustive-deps */
import { Button, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import { messageActions } from "../../store/store";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";

// バリデーション定義
const schema = z.object({
  id: z.string(),
  name: z
    .string()
    .min(1, { message: MESSAGES.REQUIRED })
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  eMailAddress: z
    .string()
    .min(1, { message: MESSAGES.REQUIRED })
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    )
    .email({ message: MESSAGES.EMAIL_ERROR }),
  companyIdBelonging: z.string().min(1, { message: MESSAGES.REQUIRED }),
  department: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  nameEnglish: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  departmentEnglish: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  tel: z
    .string()
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  isResponsible: z.boolean(),
  companyId: z.string(),
  userId: z.string(),
  userType: z.string().optional(),
});

const CompanyPersonnelForm = ({
  targetData = {},
  clientGroupCompany,
  closeHandler,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();
  const params = useParams();

  // 新規作成時にフォームをクリア
  const [id, setId] = useState();
  useEffect(() => {
    if (Object.keys(targetData).length !== 0) {
      reset({ ...targetData });
    } else {
      reset({
        id: "",
        name: "",
        companyIdBelonging: params.id,
        department: "",
        nameEnglish: "",
        departmentEnglish: "",
        eMailAddress: "",
        tel: "",
        isResponsible: false,
        companyId: params.id,
        userId: "",
      });
      setId(getValues("id"));
    }
  }, [targetData]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/UpsertClient", data);
    }, "保存しました");
    console.log("post end", data); //TODO:開発用
    closeHandler(result);
  };

  // メールアドレスの入力時に登録チェックを行う
  const dispatch = useDispatch();
  const onBlurEmail = async (e) => {
    // 空、またはアドレスに変更がない場合はスキップ
    if (!e.target.value || e.target.value === targetData.eMailAddress) return;
    const result = await connect(() => {
      return httpClient.get(
        `../../api/auditor/GetClientByEmail?email=${e.target.value}&companyId=${params.id}`
      );
    });
    if (result?.data?.data) {
      reset({
        ...result.data.data,
        //id, 対象会社を元のパラメータに戻す
        companyIdBelonging: result?.data?.data?.companyId,
        companyId: params?.id,
        id: getValues("id"),
        userId: result?.data?.data?.id,
        userType: result?.data?.data?.userType,
      });
    } else if (result?.data?.message) {
      dispatch(messageActions.setError(result?.data?.message));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="担当者名"
              required
              {...field}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="eMailAddress"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="メールアドレス"
              disabled={getValues("userStatus") === Constants.USER_STATUS.VALID}
              required
              {...field}
              onBlur={(e) => {
                field.onBlur();
                onBlurEmail(e);
              }}
              error={!!errors.eMailAddress}
              helperText={
                errors.eMailAddress ? errors.eMailAddress.message : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="companyIdBelonging"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="所属企業"
              select
              required
              {...field}
              error={!!errors.companyIdBelonging}
              helperText={
                errors.companyIdBelonging
                  ? errors.companyIdBelonging.message
                  : ""
              }
            >
              {clientGroupCompany.map((company) => (
                <MenuItem value={company.id} key={company.id}>
                  {company.companyName}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <br />
        <Controller
          name="department"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="所属部署"
              {...field}
              error={!!errors.department}
              helperText={errors.department ? errors.department.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="nameEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Name（English）"
              {...field}
              error={!!errors.nameEnglish}
              helperText={errors.nameEnglish ? errors.nameEnglish.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="departmentEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Department（English）"
              {...field}
              error={!!errors.departmentEnglish}
              helperText={
                errors.departmentEnglish ? errors.departmentEnglish.message : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="tel"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="TEL"
              {...field}
              error={!!errors.tel}
              helperText={errors.tel ? errors.tel.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="isResponsible"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="責任者"
              select
              required
              {...field}
              error={!!errors.isResponsible}
              helperText={
                errors.isResponsible ? errors.isResponsible.message : ""
              }
            >
              <MenuItem key={false} value={false}>
                いいえ
              </MenuItem>
              <MenuItem key={true} value={true}>
                はい
              </MenuItem>
            </TextField>
          )}
        />
        <br />
        <Button type="submit">保存</Button>
      </form>
    </>
  );
};

export default CompanyPersonnelForm;
