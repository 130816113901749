/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import { infoTheme } from "../../style/theme";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";
import MultiSelectInput from "../Input/MultiSelectInput";

// バリデーション定義
const schema = z
  .object({
    id: z.string(),
    clientId: z.string().optional(),
    companyCode: z
      .string()
      .min(1, MESSAGES.REQUIRED)
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    companyAbbreviation: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    companyName: z
      .string()
      .min(1, MESSAGES.REQUIRED)
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    companyNameEnglish: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    representativeName: z
      .string()
      .min(1, MESSAGES.REQUIRED)
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    representativeNameEnglish: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    representativeTitle: z
      .string()
      .min(1, MESSAGES.REQUIRED)
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    representativeTitleEnglish: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    postCode: z
      .string()
      .regex(Constants.POST_CODE_REGEXP, MESSAGES.POST_CODE_ERROR)
      .or(z.literal("")),
    prefectureCode: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    cityWardTownVillage: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    restAddress: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    addressEnglish: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    countryEnglish: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    postCodeEnglish: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      ),
    tel: z
      .string()
      .max(
        Constants.ONE_LINE_TEXT_MAX,
        MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
      )
      .regex(Constants.TEL_REGEXP, MESSAGES.TEL_ERROR),
    representativeCompanyPersonnelId: z.string(),
    teamId: z.string().optional(),
    auditorManagerInCharge: z.array(z.string()).optional(),
    auditorMemberInCharge: z.array(z.string()).optional(),
    registerStatus: z.string().min(1, { message: MESSAGES.REQUIRED }),
  })
  .refine(
    ({ id, clientId, teamId }) => {
      if (id === clientId && !teamId) return false;
      return true;
    },
    { message: MESSAGES.REQUIRED, path: ["teamId"] }
  )
  .refine(
    ({ id, clientId, auditorManagerInCharge }) => {
      if (id === clientId && auditorManagerInCharge.length === 0) return false;
      return true;
    },
    { message: MESSAGES.REQUIRED, path: ["auditorManagerInCharge"] }
  );

const CompanyForm = ({
  targetData = {},
  teamList,
  auditorList,
  companyRegisterStatusType,
  prefectureList,
  clientCode,
  clientId = "",
  closeHandler,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm({
    mode: "all",
    resolver: zodResolver(schema),
  });
  const companyCodeWatch = watch("companyCode");
  const postCodeWatch = watch("postCode");
  const { connect } = useApi();
  const role = useSelector((state) => state.role.role);

  // 新規作成時にフォームをクリア
  useEffect(() => {
    Object.keys(targetData).length !== 0
      ? reset({ ...targetData })
      : reset({
          id: "",
          clientId: clientId,
          companyCode: "",
          companyAbbreviation: "",
          companyName: "",
          companyNameEnglish: "",
          representativeName: "",
          representativeNameEnglish: "",
          representativeTitle: "",
          representativeTitleEnglish: "",
          postCode: "",
          prefectureCode: "",
          cityWardTownVillage: "",
          restAddress: "",
          addressEnglish: "",
          countryEnglish: "",
          postCodeEnglish: "",
          tel: "",
          representativeCompanyPersonnelId: "",
          // teamId: "",
          // auditorManagerInCharge: [],
          // auditorMemberInCharge: [],
          registerStatus: "UNCOMPLETED",
        });
    console.log("data", targetData);
  }, [targetData]);

  // 住所の自動入力
  useEffect(() => {
    if (postCodeWatch === targetData?.postCode && !isDirty) {
      return;
    }
    if (postCodeWatch && !errors.postCode && postCodeWatch.length === 8) {
      const fetchAddress = async () => {
        try {
          const response = await connect(() => {
            return httpClient.get(
              `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCodeWatch}`
            );
          });
          console.log("response", response);
          const data = response.data.results[0];

          if (data) {
            // 都道府県、市区町村、町名をフォームにセット
            setValue("prefectureCode", data.prefcode); // 都道府県
            setValue("cityWardTownVillage", data.address2); // 市区町村
            setValue("restAddress", data.address3); // 町名
          }
        } catch (error) {
          console.error("住所の取得に失敗しました", error);
        }
      };

      fetchAddress();
    }
  }, [postCodeWatch, errors.postCode, setValue]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/UpsertCompany", data);
    }, "保存しました");
    console.log("post end", data); //TODO:開発用
    console.log("result", result); //TODO:開発用
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ThemeProvider theme={infoTheme}>
          {/* クライアントの場合のみ会社コードと連動させる */}
          <TextField
            label="クライアントコード"
            value={
              getValues("clientId") === getValues("id")
                ? companyCodeWatch
                : clientCode
            }
          />
        </ThemeProvider>
        <br />
        <Controller
          name="companyCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="会社コード"
              required
              {...field}
              error={!!errors.companyCode}
              helperText={errors.companyCode ? errors.companyCode.message : ""}
            />
          )}
        />
        <Controller
          name="companyAbbreviation"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="会社略称"
              {...field}
              error={!!errors.companyAbbreviation}
              helperText={
                errors.companyAbbreviation
                  ? errors.companyAbbreviation.message
                  : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="companyName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="会社名"
              required
              {...field}
              error={!!errors.companyName}
              helperText={errors.companyName ? errors.companyName.message : ""}
            />
          )}
        />
        <Controller
          name="companyNameEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Corporate Name（English）"
              {...field}
              error={!!errors.companyNameEnglish}
              helperText={
                errors.companyNameEnglish
                  ? errors.companyNameEnglish.message
                  : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="representativeName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="代表者氏名"
              required
              {...field}
              error={!!errors.representativeName}
              helperText={
                errors.representativeName
                  ? errors.representativeName.message
                  : ""
              }
            />
          )}
        />
        <Controller
          name="representativeNameEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Name（English）"
              {...field}
              error={!!errors.representativeNameEnglish}
              helperText={
                errors.representativeNameEnglish
                  ? errors.representativeNameEnglish.message
                  : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="representativeTitle"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="代表者肩書"
              required
              {...field}
              error={!!errors.representativeTitle}
              helperText={
                errors.representativeTitle
                  ? errors.representativeTitle.message
                  : ""
              }
            />
          )}
        />
        <Controller
          name="representativeTitleEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Title（English）"
              {...field}
              error={!!errors.representativeTitleEnglish}
              helperText={
                errors.representativeTitleEnglish
                  ? errors.representativeTitleEnglish.message
                  : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="postCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="郵便番号"
              {...field}
              error={!!errors.postCode}
              helperText={errors.postCode ? errors.postCode.message : ""}
            />
          )}
        />
        <FormControl sx={{ minWidth: "5rem" }}>
          <InputLabel>都道府県</InputLabel>
          <Controller
            name="prefectureCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                error={!!errors.prefectureCode}
                helperText={
                  errors.prefectureCode ? errors.prefectureCode.message : ""
                }
              >
                {prefectureList?.map((prefecture) => (
                  <MenuItem value={prefecture.code} key={prefecture.code}>
                    {prefecture.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <br />
        <Controller
          name="cityWardTownVillage"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="市区町村"
              {...field}
              error={!!errors.cityWardTownVillage}
              helperText={
                errors.cityWardTownVillage
                  ? errors.cityWardTownVillage.message
                  : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="restAddress"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="番地・ビル名など"
              fullWidth
              {...field}
              error={!!errors.restAddress}
              helperText={errors.restAddress ? errors.restAddress.message : ""}
            />
          )}
        />
        <br />
        <Controller
          name="addressEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Address（English）"
              fullWidth
              {...field}
              error={!!errors.addressEnglish}
              helperText={
                errors.addressEnglish ? errors.addressEnglish.message : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="countryEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Country（English）"
              {...field}
              error={!!errors.countryEnglish}
              helperText={
                errors.countryEnglish ? errors.countryEnglish.message : ""
              }
            />
          )}
        />
        <Controller
          name="postCodeEnglish"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Post Code（English）"
              {...field}
              error={!!errors.postCodeEnglish}
              helperText={
                errors.postCodeEnglish ? errors.postCodeEnglish.message : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="tel"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="TEL"
              {...field}
              error={!!errors.tel}
              helperText={errors.tel ? errors.tel.message : ""}
            />
          )}
        />
        <Controller
          name="representativeCompanyPersonnelId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="会社担当者（代表）"
              select
              {...field}
              error={!!errors.representativeCompanyPersonnelId}
              helperText={
                errors.representativeCompanyPersonnelId
                  ? errors.representativeCompanyPersonnelId.message
                  : ""
              }
            >
              {targetData?.clientUserInChargeList?.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {getValues("clientId") === getValues("id") &&
          role === Constants.ROLE.AUDITOR && (
            <>
              <br />
              <Controller
                name="teamId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="監査担当チーム"
                    select
                    required
                    {...field}
                    error={!!errors.teamId}
                    helperText={errors.teamId ? errors.teamId.message : ""}
                  >
                    {teamList?.map((team) => (
                      <MenuItem value={team.id} key={team.id}>
                        {team.teamName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <br />
              <Controller
                name="auditorManagerInCharge"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelectInput
                    label="監査担当者（管理者）"
                    name="auditorManagerInCharge"
                    items={auditorList?.map((auditor) => ({
                      value: auditor.id,
                      label: auditor.name,
                    }))}
                    field={field}
                    errors={errors}
                    required
                  />
                )}
              />
              <Controller
                name="auditorMemberInCharge"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelectInput
                    label="監査担当者（メンバー）"
                    items={auditorList?.map((auditor) => ({
                      value: auditor.id,
                      label: auditor.name,
                    }))}
                    field={field}
                    errors={errors}
                    name="auditorMemberInCharge"
                  />
                )}
              />
            </>
          )}
        <Controller
          name="registerStatus"
          control={control}
          defaultValue="UNCOMPLETED"
          render={({ field }) => (
            <TextField
              label="入力ステータス"
              select
              required
              {...field}
              error={!!errors.registerStatus}
              helperText={
                errors.registerStatus ? errors.registerStatus.message : ""
              }
            >
              {companyRegisterStatusType?.map((status) => (
                <MenuItem value={status.code} key={status.code}>
                  {status.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <br />
        <Button type="submit">保存</Button>
      </form>
    </>
  );
};

export default CompanyForm;
